@import url(https://fonts.googleapis.com/css?family=Pacifico);

body {
    background-image: url(img/backg.jpg);
    background-color: #48b0b3;
	color:black;
}

.brand {
    margin-top: 70px;
    text-align: center;
    h1 {
        transition: .3s;
        color: white;
          font-family: 'Pacifico', cursive;
          margin-bottom: 15px;	        
    }
    p {
        color: white; 
    }
}

/*rslides*/

.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
        -webkit-backface-visibility: hidden;
        position: absolute;
        display: none;
        width: 100%;
        left: 0;
        top: 0;        
    }

    li:first-child {
        position: relative;
        display: block;
        float: left;        
    }

    img {
        margin-left: auto;
        margin-right: auto;
        max-width: 1170px;
        display: block;
        height: auto;
        /*float: left;*/
        width: 100%;
        border: 0;        
    }

}

.navbar-nav>li>a {
    font-size: 17px;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 10px;
    line-height: 20px;
}    

.navbar-default {

    .navbar-toggle {

        transition: .3s; 
        border-color: rgba(221, 221, 221, 0);          

        .icon-bar {
            background-color: #4FA9AA;
        }
    }

    
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    transition: .3s;
    background-color: rgba(73, 175, 177, 0.4);
}   

/*services section*/

.color {
    background-color: white;
  }

.about-sect {

    h2 {
        text-align: center;
        color: #4FA9AA;
        font-size: 22px;        
    }

    p {
        padding-top: 0px;
        color: black;        
    }

    &.color {
        margin-right: 15px;
        margin-left:  15px;
        border-radius: 5px;        
    }

}  

img[src="img/about-img.jpg"] {
    margin-top: 11px;
    border-radius: 22px;
    padding: 18px;
}

div#about-sec {
    margin-bottom: 29px;
}

img[src="img/sat.jpg"] {
    margin-top: 11px;
    border-radius: 22px;
    padding: 18px;
}  

img[src="img/googlemap2.jpg"] {
    border-radius: 30px;
    padding: 18px;
}      

.satdescription ul li {
  text-align:left;
}

.mathdescription ul li {
  text-align:left;
}  

.row {
    margin-left: 0;
    margin-right: 0;
}

.info-box {
    margin-bottom: 29px;
    padding-bottom: 13px;
    padding-top: 10px;
    border-radius: 5px;
    background-color: white;
    margin-top: 30px;

    h2 {
        font-size: 22px;        
    }

}

.reading {
    text-align: center;

    h2 {
        color: #4FA9AA;
    }

    i {
        margin-bottom:15px;
        color: #4FA9AA;        
    }

}  

.readinglist {

    p {
        text-align: center;
    }
        
}

.satdescription {
    padding-bottom: 122px;
    text-align: center;
    color: black;

    p strong {
        margin-bottom: 24px;
    }

}

.mathdescription {
    min-height: 284px;
    padding-bottom: 0px;
    text-align: center;
    color: black;
}

.promo-banner {
    margin-top: 24px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    border-radius: 5px;
    background-color: white;
    padding: 36.5px;
    background-image: url('../img/bkimg.jpg');
    background-repeat: no-repeat;
    background-size: 369px;
    background-position-x: 0%;
    background-position-y: 73%;    
}

.owner-cmt {
    color: #51A9AB;
    width: 458px;
    margin-left: auto;

    @media (min-width: 1200px) {
        color: #51A9AB;
        width: 559px;
        margin-left: auto;
        max-width: 495px;       
    }

    @media (max-width: 1199px) {
        color: #51A9AB;
        width: 369px;
        margin-left: auto;
        max-width: 421px;        
    }
}

.signature {
    font-family: 'Pacifico', cursive;
    font-size: 18px;
    margin: 0px;

    @media (min-width: 1200px) {
        text-align: right;
        font-family: 'Pacifico', cursive;
        font-size: 18px;
        margin: 0px;
        width: 189px;
    }

    @media (max-width: 1199px) {
        text-align: right;
        font-family: 'Pacifico', cursive;
        font-size: 18px;
        margin: 0px;
        width: 292px;        
    }
}

footer {
    margin-top: 7px;

    a {
        text-decoration: none;
        transition: .4s;
        color: white; 
        
        &:hover, &:focus {
            text-decoration: none;
            transition: .4s;
            color: black;
        }
    }

    p {
        font-size: 17px;
        color: white;
        text-align: center;        
    }
}

#contact {
    text-align: center;  
    margin-top: 19px; 
}

iframe {
    padding: 12px;
}

@media (max-width: 991px) {

    .mathdescription {
        min-height: 284px;
        text-align: center;
        color: black;
    }

    .promo-banner {
      width: 96%;
    }  


    .satdescription {
        padding-bottom: 61px;
        text-align: center;
        color: black;
    }  
  
    .owner-cmt {
        color: #51A9AB;
        width: 251px;
        margin-left: auto;
        max-width: 245px;
    }
    
    .signature {
        text-align: right;
        /* text-align: right; */
        font-family: 'Pacifico', cursive;
        font-size: 18px;
        margin: 0px;
        width: 189px;
    }    

    #sat-sec {
        text-align: center;
    }    
  
}




@media (min-width: 768px) {
  
  img[src="img/bkimg.jpg"] {
      display:none;
      width: 396px;
      margin-left: auto;
      margin-right: auto;
  }  
  
  .brand h1 {
    transition: .3s;
    font-size:52px;
  }
  
    
}


@media (max-width: 767px ) {
  
  .promo-banner {
    background-image:none;
    width: 354px;
  }
  
  img[src="img/bkimg.jpg"] {
      display:block;
      width: 396px;
      margin-left: auto;
      margin-right: auto;
  }
    
  .owner-cmt {
      color: #51A9AB;
      width: 251px;
      margin-right: auto;
      margin-left: auto;
      max-width: 245px;
  } 
  
  .info-box {
      margin-left: auto;
      margin-right: auto;
      width: 354px;
  }  

  .readinglist ul {

    margin-right: auto;
    margin-left: auto;
  }

  .about-sect.color {
      width: 353px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 5px;
  }
  
}



@media (max-width: 450px ) {
  
  .info-box {
      width: 100%;
  }
  
  .promo-banner {
      background-image: none;
      width: 92%;
  }
  
  .owner-cmt {
      color: #51A9AB;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
  } 
  
  .signature {
      text-align: right;
      font-family: 'Pacifico', cursive;
      font-size: 18px;
      margin: 0px;
      width: 100%;
  } 

  .about-sect.color {
      width: 92%;
      margin-right: auto;
      margin-left: auto;
      border-radius: 5px;
  }

  
}

@media (max-width: 389px ) {
  
  .readinglist ul {
    width: 82%;
  }
  
}
@media (max-width: 355px ) {
  
  .readinglist ul {
    width: 100%;   
  }
  
}

@media (max-width: 355px ) {
  
  .navbar-brand {
      float: left;
      height: 50px;
      padding: 15px 15px;
      font-size: 12px;
      line-height: 20px;
  }
  
}

